import { Control, Controller, FormState, UseFormRegister, UseFormTrigger } from "react-hook-form";
import { RegisterFormType } from "./Register";
import { lang } from "../../utilities/lang";
import ReactFlagsSelect from "react-flags-select";
import { makeTwoWayObject } from "../../helpers/helpers";

export type RHFTextInputProps = {
    label: string;
    formState: FormState<Partial<RegisterFormType>>;
    register: UseFormRegister<Partial<RegisterFormType>>;
    trigger: UseFormTrigger<Partial<RegisterFormType>>;
    fieldName:
        | "firstname"
        | "lastname"
        | "email"
        | "tel1"
        | "tel2"
        | "street"
        | "number"
        | "postcode"
        | "city"
        | "company"
        | "country";
    required: boolean;
    control?: Control<RegisterFormType>;
    pattern?: { value: RegExp; message: string };
};

export const RHFTextInput = ({
    label,
    formState,
    register,
    fieldName,
    trigger,
    required,
    pattern,
}: RHFTextInputProps) => {
    return (
        <>
            <label className={"text-input"} aria-label={label}>
                <input
                    className={`${formState.errors[fieldName] ? "input-error" : ""}`}
                    type="text"
                    autoComplete={fieldName}
                    {...register(fieldName, {
                        required: required ? `${label} ${lang.get("erforderlich")}` : false,
                        onBlur: () => {
                            trigger(fieldName);
                        },
                        pattern: pattern,
                    })}
                />
                {formState.errors[fieldName] && <span className={"error"}>{formState.errors[fieldName].message}</span>}
            </label>
        </>
    );
};

export const RHFPhoneInput = ({ label, formState, register, trigger, control, required }: RHFTextInputProps) => {
    const numberCodeMapper = makeTwoWayObject({ AT: "43", CH: "41", DE: "49", FR: "33", GB: "44", IT: "39" });
    const exchangeValue = (key: string | number) => {
        return numberCodeMapper[key];
    };

    return (
        <label className={"phone-input"} aria-label={label}>
            <Controller
                name={"tel1"}
                control={control}
                render={({ field }) => (
                    <ReactFlagsSelect
                        className={"number-select"}
                        countries={["AT", "CH", "DE", "IT", "GB", "FR"]}
                        selected={field.value ? exchangeValue(field.value) : "AT"}
                        customLabels={{ AT: "+43", CH: "+41", DE: "+49", FR: "+33", GB: "+44", IT: "+39" }}
                        onSelect={async (code) => {
                            field.onChange(exchangeValue(code));
                            await trigger("tel1");
                        }}
                        rfsKey={"tel1"}
                    />
                )}
            />
            <input
                className={`tel-number ${formState.errors["tel2"] ? "input-error" : ""}`}
                type="text"
                placeholder={"123456"}
                autoComplete={"tel2"}
                {...register("tel2", {
                    required: required ? `${label} ${lang.get("erforderlich")}` : false,
                    onBlur: () => {
                        trigger("tel2");
                    },
                    pattern: {
                        value: /^(0|[1-9]\d*)(\.\d+)?$/,
                        message: "Telefonnummer darf nur Zahlen enthalten",
                    },
                })}
            />
            {formState.errors["tel2"] && <span className={"error"}>{formState.errors["tel2"].message}</span>}
        </label>
    );
};
