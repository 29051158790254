import { Visibility } from "../../assets/Visibility";
import { VisibilityOff } from "../../assets/VisibilityOff";
import { RegisterFormType } from "./Register";
import { useState } from "react";
import { FormState, UseFormGetValues, UseFormRegister, UseFormTrigger } from "react-hook-form";
import { LoginFormType } from "./Login";
import { lang } from "../../utilities/lang";

type PasswordInputProps = {
    label: string;
    formState: FormState<Partial<LoginFormType>>;
    register: UseFormRegister<Partial<LoginFormType>>;
    fieldName: "password";
    onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
};

export type RHFPasswordInputProps = {
    label: string;
    formState: FormState<Partial<RegisterFormType>>;
    register: UseFormRegister<Partial<RegisterFormType>>;
    getValues: UseFormGetValues<Partial<RegisterFormType>>;
    trigger: UseFormTrigger<Partial<RegisterFormType>>;
    fieldName: "password" | "password_confirmation";
};

export const PasswordInput = ({ label, formState, register, fieldName, onKeyDown }: PasswordInputProps) => {
    const [inputType, setInputType] = useState<"password" | "text">("password");

    return (
        <label className={"password-input"} aria-label={label}>
            <div className={"password-wrapper"}>
                <input
                    className={`${formState.errors[fieldName] ? "input-error" : ""}`}
                    type={inputType}
                    autoComplete={"new-password"}
                    {...register(fieldName, {
                        required: `${label} ${lang.get("erforderlich")}`,
                    })}
                    onKeyDown={(e) => {
                        if (onKeyDown) {
                            onKeyDown(e);
                        }
                    }}
                />
                <button
                    type={"button"}
                    tabIndex={-1}
                    className={"password-visibility"}
                    onClick={() => {
                        if (inputType === "password") {
                            setInputType("text");
                        } else {
                            setInputType("password");
                        }
                    }}
                >
                    {inputType === "password" ? <Visibility /> : <VisibilityOff />}
                </button>
            </div>
            {formState.errors[fieldName] && <span className={"error"}>{formState.errors[fieldName]?.message}</span>}
        </label>
    );
};

export const RHFPasswordInput = ({
    label,
    formState,
    register,
    fieldName,
    trigger,
    getValues,
}: RHFPasswordInputProps) => {
    const [inputType, setInputType] = useState<"password" | "text">("password");

    return (
        <>
            <label className={"password-input"} aria-label={label}>
                <div className={"password-wrapper"}>
                    <input
                        className={`${formState.errors[fieldName] ? "input-error" : ""}`}
                        type={inputType}
                        autoComplete={"new-password"}
                        {...register(fieldName, {
                            required: `${label} ${lang.get("erforderlich")}`,
                            minLength: 8,
                            validate: () => {
                                const password = getValues("password");
                                const passwordValidation = getValues("password_confirmation");
                                if (password === "" || passwordValidation === "" || password === passwordValidation) {
                                    return true;
                                } else {
                                    return lang.get("Passwörter stimmen nicht überein");
                                }
                            },
                            onBlur: () => {
                                trigger(["password", "password_confirmation"]);
                            },
                        })}
                    />
                    <button
                        type={"button"}
                        tabIndex={-1}
                        className={"password-visibility"}
                        onClick={() => {
                            if (inputType === "password") {
                                setInputType("text");
                            } else {
                                setInputType("password");
                            }
                        }}
                    >
                        {inputType === "password" ? <Visibility /> : <VisibilityOff />}
                    </button>
                </div>
                {formState.errors[fieldName] && <span className={"error"}>{formState.errors[fieldName]?.message}</span>}
            </label>
        </>
    );
};
