import { lang } from "../../utilities/lang";
import { useGetResetPasswordLink } from "../../api/useAuth";
import { useState } from "react";
import { TextInput } from "./RFHInputs";
import { useForm } from "react-hook-form";
import { getResponseError } from "../../helpers/helpers";
import { AxiosError } from "axios";

export const PasswordReset = () => {
    const [reset, setReset] = useState<boolean>(false);
    const [error, setError] = useState<string>("");
    const [success, setSuccess] = useState<string>("");
    const form = useForm<{ email: string }>();
    const resetPassword = useGetResetPasswordLink();

    const submit = async (data: { email: string }) => {
        setSuccess("");
        setError("");
        try {
            const response = await resetPassword.mutateAsync(data.email);
            if (response.status === 200) {
                setSuccess(lang.get("Email gesendet"));
                setReset(false);
            }
        } catch (e) {
            const errors = getResponseError(e as AxiosError);
            errors.forEach((error) => {
                if (!error.key) {
                    setError(error.error);
                }
            });
        }
    };

    return (
        <div className="password-reset">
            {lang.get("entry.passwortResetText")}
            {error ? <div className={"error"}>{error}</div> : null}
            {success ? <div className={"success"}>{success}</div> : null}
            {reset ? (
                <form onSubmit={form.handleSubmit(submit)} className={"reset-password-form"}>
                    <TextInput form={form} fieldName={"email"} label={lang.get("Email")} required={"required"} />
                    <div className={"button-group"}>
                        <button type={"submit"} className={"button"}>
                            {lang.get("Zurücksetzen")}
                        </button>
                        <button
                            type={"button"}
                            className={"button"}
                            onClick={() => {
                                setReset(false);
                            }}
                        >
                            {lang.get("Abbrechen")}
                        </button>
                    </div>
                </form>
            ) : (
                <>
                    <button
                        className="button"
                        onClick={() => {
                            setReset(true);
                        }}
                    >
                        {lang.get("Passwort zurücksetzen")}
                    </button>
                </>
            )}
        </div>
    );
};
